<template>
	<div>
		<ContentHeader title="Setup Cabang" url="/setup-cabang" title2="Setup Cabang" url2="/data-cabang" subTitle="Edit Data Cabang" />
		<div class="content-header">
			<div class="container-fluid">
				<div class="card">
	              <div class="card-header">
	                <h3 class="card-title"><div style="#007bff">Edit Data Cabang</div></h3>

	                <div class="card-tools">
	                </div>
	              </div>
	              <div class="card-body row">
	              	<div class="col-lg-2"></div>
	              	<div class="col-lg-8">
	              		<form @submit.prevent="updateDataCabang">
		                	<div class="px-lg-2 ">
			                  <div class="form-group">
			                    <label for="kode_cabang">Kode Cabang <span class="text-danger">*</span></label>
			                    <input type="text" class="form-control" id="kode_cabang" name="kode_cabang" v-model="cabang.kode_cabang" autocomplete="off" required="" disabled>
			                  </div>
			                </div>
			                <div class="px-lg-2 ">
			                  <div class="form-group">
			                    <label for="kode_wilayah">Kode Wilayah <span class="text-danger">*</span></label>
			                    <input type="text" class="form-control" id="kode_wilayah" name="kode_wilayah" v-model="cabang.kode_wilayah" autocomplete="off" required="" disabled>
			                  </div>
			                </div>
		                	<div class="px-lg-2 ">
			                  <div class="form-group">
			                    <label for="nama">Nama Cabang <span class="text-danger">*</span></label>
			                    <input type="text" class="form-control" id="nama" name="nama" v-model="cabang.nama" required="" autocomplete="off">
			                  </div>
			                </div>
			                <div class="px-lg-2 ">
			                  <div class="form-group">
			                    <label for="alamat">Alamat <span class="text-danger">*</span></label>
			                    <input type="text" class="form-control" id="alamat" name="alamat" v-model="cabang.alamat" autocomplete="off" required="">
			                  </div>
			                </div>
		                	<div class="px-lg-2 ">
			                  <div class="form-group">
			                    <label for="telepon">Telepon <span class="text-danger">*</span></label>
			                    <input type="tel" class="form-control" id="telepon" name="telepon" v-model="cabang.telepon" autocomplete="off" required="">
			                  </div>
			                </div>
			                <div class="px-lg-2 ">
			                  <div class="form-group">
			                    <label for="sio">SIO (Surat Izin Operasi)</label>
			                    <input type="text" class="form-control" id="sio" name="sio" v-model="cabang.sio" autocomplete="off">
			                  </div>
			                </div>
			                <div class="px-lg-2 ">
			                  <label for="masa_awal_berlaku">Masa Berlaku SIO</label>
			                  <div class="row">
			                  	<div class="col">
			                  		<div class="form-group">
					                    <span for="awal_berlaku_sio">Awal Berlaku SIO</span>
					                    <input type="date" class="form-control" id="awal_berlaku_sio" name="awal_berlaku_sio" v-model="cabang.awal_berlaku_sio" autocomplete="off">
					                  </div>
			                  	</div>
			                  	<div class="col">
			                  		<div class="form-group">
					                    <span for="akhir_berlaku_sio">Akhir Berlaku SIO</span>
					                    <input type="date" class="form-control" id="akhir_berlaku_sio" name="akhir_berlaku_sio" v-model="cabang.akhir_berlaku_sio" autocomplete="off">
					                  </div>
			                  	</div>
			                  </div>
			                </div>
			                <div class="px-lg-2 ">
			                  <div class="form-group">
			                    <label for="npwp">NPWP</label>
			                    <input type="text" class="form-control" id="npwp" name="npwp" v-model="cabang.npwp" autocomplete="off">
			                  </div>
			                </div>
			                <div class="px-lg-2 ">
			                  <div class="form-group">
			                    <label for="nomor_handphone">Nomor Handphone</label>
			                    <input type="tel" class="form-control" id="nomor_handphone" name="nomor_handphone" v-model="cabang.nomor_handphone" autocomplete="off">
			                  </div>
			                </div>
		                	<div class="px-lg-2 ">
			                  <div class="form-group">
			                    <label for="penanggung_jawab">Penanggung Jawab</label>
			                    <input type="text" class="form-control" id="penanggung_jawab" name="penanggung_jawab" v-model="cabang.penanggung_jawab" autocomplete="off">
			                  </div>
			                </div>
			                <div class="px-lg-2 ">
			                  <div class="form-check">
								  <input class="form-check-input" type="checkbox" v-model="cabang.detail_struk" id="defaultCheck1">
								  <label class="form-check-label" for="defaultCheck1">
								    Sembunyikan detail Obat Resep (Centang Untuk : YA)
								  </label>
							  </div>
			                </div>
			                <div class="px-lg-2">
			                	<button type="submit" class="btn btn-primary d-flex align-items-center mt-4">
									<div>Submit</div>	
									<div class="spinner-border spinner-border-sm ml-2" v-if="loading" role="status">
									  <span class="sr-only">Loading...</span>
									</div>
								</button>
			                </div>
		                </form>
	              	</div>
	              	<div class="col-lg-2"></div>
	              </div>
	              <!-- /.card-body -->
	              <div class="card-footer" style="display: block;">
	                
	              </div>
	              <!-- /.card-footer-->
            	</div>
			</div>
		</div>
	</div>
</template>

<script>
import ContentHeader3 from '@/components/ContentHeader3'

import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import axios from 'axios'
import router from '@/router'
import Swal from 'sweetalert2'

export default{
	components:{
		ContentHeader: ContentHeader3
	},
	setup(){
		const route = useRoute()
		const cabang_id = route.params.id
		const cabang = ref([])
		
		const getCabangWhereId = async () => {
			let response = await axios.get(`api/cabang/getWhereId/${cabang_id}`)
			cabang.value = response.data
			if (cabang.value.detail_struk == 1) {
				cabang.value.detail_struk = true
			}
		}

		const updateDataCabang = () => {
			axios.put(`api/cabang/update/${cabang_id}`, cabang.value)
			.then((response) => {
				Swal.fire({
				  title: 'Berhasil!',
				  text: 'Berhasil mengedit data',
				  icon: 'success',
				  showConfirmButton: false,
				  timer: 1500
				})	
	    		console.log(response.data)
	    		router.push('/setup-cabang')
	    	})
	    	.catch((error) => {
	    		Swal.fire({
				  title: 'Gagal!',
				  text: 'Gagal mengedit data',
				  icon: 'error',
				  showConfirmButton: false,
				  timer: 1500
				})	
	    		console.log(error)
	    	})	

		}

		onMounted(() => {
			getCabangWhereId()
		})

		return {
			cabang, updateDataCabang
		}
	}
}

</script>